import React, {useEffect, useState} from "react";
import _ from "lodash";
import {fieldTypes} from "../services/utils";
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Slide,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {MobileDatePicker} from "@mui/x-date-pickers/MobileDatePicker";
import {LocalizationProvider, MobileDateTimePicker, TimePicker} from "@mui/x-date-pickers";
import {AddCircle, ContentCopy, VideoCameraFront} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import {API_URL} from "../config";
import CustomTooltip from "./CustomTooltip";
import {useParams} from "react-router-dom";
import AuthService from "../services/auth.service";

const CustomField = ({userId, session, formik, field, disabled}) => {
    const [isAdmin, setAdmin] = useState(false)

    useEffect( () => {
        async function getCurrentUserAdmin() {
            const res = await AuthService.getCurrentUserAdmin()
            setAdmin(res)
        }
        getCurrentUserAdmin();
    }, [AuthService])

    const {id: user_id} = useParams()
    const fieldType = _.find(fieldTypes, ['type', field.type])

    function getOptions() {
        switch (field.id) {
            case "luogoArrivo":
                return formik.values['mezzoDiTrasporto'] === "In aereo" ? ["Linate", "Malpensa"]
                    : formik.values['mezzoDiTrasporto'] === "In treno" ? ["Milano Centrale", "Milano Garibaldi"]
                        : (field?.values || [])
            default:
                return field?.values || []

        }
    }

    function getValue() {
        return field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]
    }

    function getOtherValue() {
        const val = getValue()
        switch (field.id) {
            case "luogoArrivo":
                return getOptions().includes(val) ? "" : val
            case "luogoPartenzaRitorno":
                return field.values.includes(val) ? "" : val
            default:
                return val

        }
    }

    //console.log("formik:",formik)

    return (
        <Slide in={true} exit={true}
               children={<Grid item xs={12} md={field?.cols?.md || fieldType?.md || 6}>
                   {(() => {
                       switch (fieldType) {
                           case fieldTypes.RADIO_GROUP:
                               return <FormControl fullWidth disabled={field.precompiled || disabled}>
                                   <Typography variant={'body2'}>{field.label || field.headerName}</Typography>
                                   <RadioGroup
                                       row
                                       value={field.getter ? formik.values[field.getter][field.id] : formik.values[field.id]}
                                       onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                       onBlur={formik.handleBlur}
                                       name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                   >
                                       {
                                           (field.values || getOptions()).map((radio) =>
                                               <FormControlLabel key={radio.value} value={radio.value} control={
                                                   <Radio required/>} label={radio.label}/>
                                           )
                                       }
                                       {field.other
                                           && (field.condition && formik.values[field.condition.id] === field.condition.value)
                                           && <FormControlLabel
                                               checked={!getOptions().includes(getValue())}
                                               control={<Radio/>} label={<TextField
                                               name={field.getter ? `${field.getter}.${field.id}` : field.id}
                                               value={getOtherValue()}
                                               onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                               onBlur={formik.handleBlur}
                                               variant={"standard"} placeholder={'Altro:'}/>
                                           }/>}
                                   </RadioGroup>
                                   {field.id === 'partecipazione' && (field.getter ?
                                       formik.values[field.getter][field.id] : formik.values[field.id]) === 'no'
                                       && <FormHelperText error sx={{maxWidth: '270px'}}>
                                               Modificando la partecipazione a "NO" verranno resettati i campi relativi alla
                                               logistica e ai documenti
                                           </FormHelperText>}
                               </FormControl>
                           case fieldTypes.CHECK_BOX:
                               return <FormControl>
                                   <FormGroup>
                                       <FormControlLabel
                                           control={<Checkbox
                                               id={field.id}
                                               name={field.id}
                                               disabled={field.precompiled || disabled}
                                               checked={formik.values[field.id]}
                                               //onChange={formik.handleChange}
                                               //onBlur={formik.handleBlur}
                                               onChange={(event, checked) => {
                                                   if (!field.precompiled && !disabled) {
                                                       formik.setFieldValue(field.id, checked)
                                                       formik.setFieldTouched(field.id)
                                                   }
                                               }}
                                           />} label={field.label}/>
                                   </FormGroup>
                               </FormControl>
                           case fieldTypes.SELECT:
                               return <FormControl variant="standard" fullWidth>
                                   <FormHelperText>{field.label}</FormHelperText>
                                   <Select
                                       disabled={field.precompiled || disabled}
                                       label={field.label}
                                       id={field.id}
                                       name={field.id}
                                       //value={dati[field.id]}
                                       value={formik.values[field.id]}
                                       onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                       onBlur={formik.handleBlur}
                                       //error={Boolean(formik.errors[field.id])}
                                       //helperText={formik.errors[field.id] ? formik.errors[field.id] : null}
                                       //helperText={field.label}
                                   >
                                       {(field?.values || getOptions())
                                           .filter((opt) => {
                                               if(session === '0723')
                                                   return opt.label.includes('Luglio')
                                               if(session === '1023')
                                                   return opt.label.includes('Ottobre') && opt.label.includes('2023')
                                               if(session === '0524')
                                                   return opt.label.includes('Maggio')
                                               if(session === '1024')
                                                   return opt.label.includes('Ottobre') && !opt.label.includes('2023')
                                           })
                                           .map(v =>
                                           <MenuItem value={v.value} key={v.value}>{v.label}</MenuItem>
                                       )}
                                   </Select>
                               </FormControl>
                           case fieldTypes.DATE:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDatePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input" fullWidth
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           //console.log("formatMySqlDate:",formatMySqlDate(date, formik.values.startingTime))
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(formik.values[field.id]?._seconds ?
                                           formik.values[field.id]._seconds * 1000
                                           : formik.values[field.id]
                                       ) || ''}
                                       inputFormat="dd/MM/yyyy"/>
                               </LocalizationProvider>
                           case fieldTypes.DATE_TIME:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <MobileDateTimePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input" fullWidth
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       ampm={false}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(formik.values[field.id]?._seconds ?
                                           formik.values[field.id]._seconds * 1000
                                           : formik.values[field.id]
                                       ) || ''}
                                       inputFormat="dd/MM/yyyy, HH:mm"/>
                               </LocalizationProvider>
                           case fieldTypes.TIME:
                               return <LocalizationProvider dateAdapter={AdapterDateFns}>
                                   <TimePicker
                                       renderInput={(params) =>
                                           <TextField {...params} id="name-input"
                                                      variant={'standard'}/>}
                                       label={field.label}
                                       onChange={(date, selectionState) => {
                                           if (!field.precompiled && !disabled) {
                                               formik.setFieldValue(field.id, date)
                                               formik.setFieldTouched(field.id)
                                           }
                                       }}
                                       ampm={false}
                                       disabled={field.precompiled || disabled}
                                       value={new Date(formik.values[field.id]) || ''}
                                       inputFormat="HH:mm"/>
                               </LocalizationProvider>
                           case fieldTypes.URL:
                               return formik.values[field.id] ?
                                   <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                       <CustomTooltip
                                           title={'Apri link in un\'altra finestra'}
                                           children={<Button href={field.getter ?
                                               formik.values[field.getter][field.id]
                                               : formik.values[field.id]}
                                                             target={'_blank'}
                                                             color={'accent'}
                                                             size={'small'}
                                                             variant={'contained'}
                                                             sx={{paddingBlock: 1, color: 'white'}}
                                                             onClick={(event) => event.stopPropagation()}
                                                             endIcon={<VideoCameraFront/>}>
                                               Link {field.id === "videoUrl" ? "al video" : "streaming"}
                                           </Button>}/>
                                       <CustomTooltip
                                           title={"Copia link"}
                                           children={<IconButton color={'primary'}
                                                                 onClick={() =>
                                                                     navigator.clipboard.writeText(field.getter
                                                                         ? formik.values[field.getter][field.id]
                                                                         : formik.values[field.id])
                                                                 }>
                                               <ContentCopy fontSize={'small'}/>
                                           </IconButton>}/>
                                   </Box> : ''
                           case fieldTypes.ACCESS_TYPE:
                               if (formik.values.partecipazione === 'si') {
                                   return <Stack direction={"row"} spacing={1} justifyContent={'center'}>
                                           {!!formik.values.zoomUrl ?
                                               <CustomTooltip title={"Copia link Zoom personale"} children={
                                                   <IconButton
                                                       color={'accent'}
                                                       variant={'outlined'}
                                                       onClick={async (event) => {
                                                           event.stopPropagation()
                                                           await navigator.clipboard.writeText(
                                                               formik.values.zoomUrl || ``)
                                                       }}>
                                                       <ContentCopy/>
                                                   </IconButton>
                                               }/>
                                               : (isAdmin) ?
                                                   <CustomTooltip title={"Genera link Zoom personale"} children={
                                                       <IconButton
                                                           color={'accent'}
                                                           variant={'outlined'}
                                                           disabled={formik.values.zoomUrl}
                                                           onClick={async (event) => {
                                                               event.stopPropagation()
                                                               axios.post(`${API_URL}zoom`, {id: user_id})
                                                                   .then((res) => {
                                                                       console.log("res:",res)
                                                                   })
                                                                   .catch((err) => {console.log("err:",err)})
                                                           }}>
                                                           <AddCircle/>
                                                       </IconButton>
                                                   }/>
                                                   : null
                                           }
                                           <CustomTooltip title={"Apri link zoom personale"} children={
                                               <Button href={formik.values.zoomUrl || ``}
                                                       target={'_blank'}
                                                       color={'accent'}
                                                       variant={'outlined'}
                                                       endIcon={<VideoCameraFront/>}
                                               >
                                                   Zoom Link
                                               </Button>
                                           }/>
                                   </Stack>
                               }
                               return null

                           case fieldTypes.TEXT_BOX:
                               return <TextField id={field.id}
                                                 name={field.id}
                                                 disabled={field.precompiled || disabled}
                                                 multiline={true}
                                                 minRows={2}
                                                 maxRows={3}
                                                 value={formik.values[field.id]}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"outlined"}
                                                 sx={{width: '100%'}}/>
                           case fieldTypes.TEXT:
                           case fieldTypes.NUMBER:
                               let val = null
                               if (field.id === 'id')
                                   val = user_id

                               return <TextField id={field.id}
                                                 name={field.id}
                                                 disabled={field.precompiled || disabled}
                                                 value={val || formik.values[field.id]}
                                                 onChange={(!field.precompiled && !disabled) ? formik.handleChange : null}
                                                 onBlur={formik.handleBlur}
                                                 label={field.label}
                                                 variant={"standard"}
                                                 sx={{width: '100%'}}/>

                           case fieldTypes.DIVIDER:
                               return <Divider
                                   children={field?.label && <Chip size={'small'} label={field?.label || ''}/>}/>

                           default:
                               return null
                       }
                   })()}
               </Grid>}
        />)
}

export default CustomField