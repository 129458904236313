import {
    AccessTime,
    AlternateEmail,
    CheckBox,
    CheckBoxOutlineBlank,
    DateRange,
    HorizontalRule,
    Info,
    Link,
    MenuOpen,
    Notes,
    QrCodeScanner,
    RadioButtonChecked,
    TextFields,
    Today,
    Tune
} from "@mui/icons-material";
import React from "react";
import {camelCase, replace} from "lodash";

export const fieldTypes = {
    TEXT: {icon: <TextFields color={'disabled'}/>, type: "text", label: "Testo", admin: false},
    NUMBER: {icon: <TextFields color={'disabled'}/>, type: "number", label: "Numero", admin: false},
    EMAIL: {icon: <AlternateEmail color={'disabled'}/>, type: "email", label: "E-mail", admin: false},
    SELECT: {icon: <MenuOpen color={'disabled'}/>, type: "select", label: "Menù a scelta", admin: true},
    DATE: {icon: <DateRange color={'disabled'}/>, type: "date", label: "Data", admin: false},
    DATE_TIME: {icon: <Today color={'disabled'}/>, type: "datetime", label: "Data e Ora", admin: false},
    TIME: {icon: <AccessTime color={'disabled'}/>, type: "time", label: "Ora", admin: false},
    RADIO_GROUP: {icon: <RadioButtonChecked color={'disabled'}/>, type: "radiogroup", label: "Radio group", admin: true},
    CHECK_BOX: {icon: <CheckBox color={'disabled'}/>, type: "checkbox", label: "Checkbox", md: 12, admin: true},
    TEXT_BOX: {icon: <Notes color={'disabled'}/>, type: "textbox", label: "Area di testo", admin: false},
    URL: {icon: <Link color={'disabled'}/>, type: "url", label: "URL", admin: false},
    CUSTOM: {icon: <Tune color={'disabled'}/>, type: "custom", label: "Custom", admin: true},
    ACCESS_TYPE: {icon: <QrCodeScanner color={'disabled'}/>, type: "accessType", label: "Tipo di accesso", admin: true},
    NOTE: {icon: <Info color={'disabled'}/>, type: "note", label: "Note", admin: true, onlyUserForm: true},
    DIVIDER: {icon: <HorizontalRule color={'disabled'}/>, type: "divider", label: "", admin: true, noDataGrid: true},
}

export const getLocaleDateTime = ({ts_seconds}) => {
    return new Date(ts_seconds * 1000).toLocaleString('it-IT')
}

export const icon = <CheckBoxOutlineBlank fontSize="small" />;
export const checkedIcon = <CheckBox fontSize="small" />;

export function getId(fieldName) {
    return fieldName ? camelCase(fieldName.trim()
        .replaceAll(/[^a-zA-Z0-9\s]+/g, "")
        .replaceAll(/\s+/g, "-")) : ""
}

export function parseMultiLineTextBox(value) {
    return value ?
        replace(replace(value.trim(), /\t|\n/g, " "), /\s{2,}/g, " ")
        : "";
}

export const sessions = {
    '0723': {
        number: 1,
        label: "Sessione LUGLIO 23"
    },
    '1023': {
        number: 2,
        label: "Sessione OTTOBRE 23"
    },
    '0524': {
        number: 3,
        label: "Sessione MAGGIO 24"

    },
    '1024': {
        number: 4,
        label: "Sessione OTTOBRE 24"
    }
}